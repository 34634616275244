import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

import { saveLead, Ballot } from '@ballotready/redux-api-client'
import { AppConfig } from 'appConfig'
import { StoreState } from 'types'
import { Action } from 'redux'

export interface LeadForms {}

interface AppState {
  appConfig: AppConfig
  ballot: Ballot
}

const mapStateToProps = ({
  appConfig: { sampleBallotAddress, tenant },
  ballot,
}: AppState) => ({
  currentAddress: ballot && ballot.address,
  currentEmail: ballot && ballot.email,
  sampleBallotAddress,
  tenant,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, null, Action>,
  { onSubmit }: any,
) => ({
  onSubmit: async (values: LeadForms) => {
    await dispatch(saveLead(values))
    onSubmit && onSubmit()
  },
})

export default connect(mapStateToProps, mapDispatchToProps)
