import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@ballotready/sparkles'
import './IneligibleModal.css'

interface Props {
  onClose(): void
}

const IneligibleModal = ({ onClose }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(true)
  const { t } = useTranslation()

  const handleClose = () => {
    setIsModalOpen(false)
    onClose && onClose()
  }

  return (
    <Modal
      appElement={document.getElementById('root')}
      className="IneligibleModal"
      isOpen={isModalOpen}
      onClose={handleClose}
    >
      <div>
        <h1>{t('AddressForm.IneligibleModal.title')}</h1>
        <p>{t('AddressForm.IneligibleModal.message')}</p>
      </div>
    </Modal>
  )
}

export default IneligibleModal
