import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Dashboard } from 'assets/images/dashboard.svg'
import styles from './ElectionCenterMobileNavButton.module.scss'
import getPathForApp from 'utils/getPathForApp'

const ElectionCenterMobileNavButton = () => {
  return (
    <Link to={getPathForApp('election_center')} className={styles.ecNavButton}>
      <Dashboard />
    </Link>
  )
}

export default ElectionCenterMobileNavButton
