import * as types from 'actions/constants'

import { RecruitmentSelections } from '../types'
import { AnyAction } from 'redux'
import {
  Candidate,
  Position,
  types as apiTypes,
} from '@ballotready/redux-api-client'

const initialState: RecruitmentSelections = {
  values: null,
  positions: [],
}

export default (
  state = initialState,
  action: AnyAction,
): RecruitmentSelections => {
  switch (action.type) {
    case types.SET_RECRUITMENT_VALUES:
      return {
        ...state,
        values: action.values,
      }
    case apiTypes.RECEIVE_POSITIONS:
      const positions: Position[] = []
      ;(window as any).positions = action.payload

      action.payload
        .filter(
          (position: Position, idx: number, self: any) =>
            self.findIndex(
              (p2: any) => p2.position_id === position.position_id,
            ) === idx,
        )
        .forEach((position: Position) => {
          const candidates: Candidate[] = []
          position.candidates?.map((candidate: any) => {
            candidate.endorsements = candidate.endorsements.map(
              (endorsement: number) => ({
                organization_id: endorsement,
              }),
            )
            candidates.push({
              ...candidate,
              endorsements: candidate.endorsements,
            })
          })
          positions.push({ ...position, candidates: candidates })
        })

      return {
        ...state,
        positions: positions,
      }
    default:
      return state
  }
}
