import { dateToInt, types as apiTypes } from '@ballotready/redux-api-client'
import dateToLocalISOString from 'utils/dateToLocalISOString'

export default (state = null, action) => {
  switch (action.type) {
    case apiTypes.RECEIVE_ELECTION:
      const election = action.payload

      if (!election) {
        return null
      }

      const electionDay = dateToInt(election.election_day)
      const today = dateToInt(dateToLocalISOString(new Date()))
      if (today < electionDay) {
        return 'early'
      } else if (today === electionDay) {
        return 'election_day'
      } else {
        return 'closed'
      }
    case apiTypes.CLEAR_ELECTION:
      return null
    default:
      return state
  }
}
