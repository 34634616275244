import React from 'react'
import { withRouter } from 'react-router-dom'

import { Button } from '@ballotready/sparkles'
import signOutButtonContainer from './signOutButtonContainer'
import { useTranslation } from 'react-i18next'

export interface Props {
  homeUrl: string | null
  onClick(homeUrl: string | null): void
}

const SignOutButton: React.FC<Props> = ({ homeUrl, onClick }) => {
  const { t } = useTranslation()
  return (
    <Button
      onClick={() => onClick && onClick(homeUrl)}
      outline
      size="sm"
      variant="default"
    >
      {t('LocalNav.signOut')}
    </Button>
  )
}

export default withRouter(signOutButtonContainer(SignOutButton))
