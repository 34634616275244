import React from 'react'
import classNames from 'classnames'
import Select, { components, MenuProps } from 'react-select'
import { useTranslation } from 'react-i18next'
import { ValueType } from 'react-select/src/types'
import { OptionType } from 'types'
import styles from './LocaleSwitcher.module.scss'

export interface Props {
  className?: string
  currentLocale: string
  locales: string[]
  onChange(locale?: string | null): void
}

// Help widget determine how big to grow
const Menu: React.FC<MenuProps<OptionType>> = props => (
  <components.Menu {...props}>
    <>
      {props.children}
      <span data-iframe-height></span>
    </>
  </components.Menu>
)

const LocaleSwitcher = ({
  className,
  currentLocale,
  locales = [],
  onChange,
}: Props) => {
  const { t } = useTranslation()

  const options = locales.map(locale => {
    const localeComponents = locale.split('-')

    // Turns a string like 'es-US-cof70' into an array :
    // ["locales.en-US-cof70", "locales.en-US", "locales.en"]
    // We can use this array to fallback through the locales until i18next finds
    // a translated value.
    const labelLocales = localeComponents
      .map(
        (_: string, i: number) =>
          `locales.${localeComponents.slice(0, i + 1).join('-')}`,
      )
      .reverse()
      .filter((el: string) => el)

    return {
      label: t(labelLocales),
      value: locale,
    }
  })
  const selected = options.find(o => o.value === currentLocale)

  const handleChange = (value: ValueType<OptionType>): void =>
    onChange(value && (value as OptionType).value)

  return (
    <Select
      className={classNames(styles.switcher, className)}
      components={{ Menu }}
      defaultValue={selected}
      menuPlacement="top"
      onChange={handleChange}
      options={options}
      placeholder={t('sparkles:LocaleSwitcher.placeholder')}
    />
  )
}

export default LocaleSwitcher
