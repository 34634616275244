import {
  Candidacy,
  Candidate,
  Position,
  types as apiTypes,
} from '@ballotready/redux-api-client'
import { AnyAction } from 'redux'

import * as types from 'actions/constants'

import { SearchResults } from '../types'

const initialState: SearchResults = {
  positions: [],
  candidates: [],
  searchInitiated: false,
  searchResults: [],
  searchTerm: '',
  searching: false,
}

export default (state = initialState, action: AnyAction): SearchResults => {
  switch (action.type) {
    case apiTypes.RECEIVE_ENDORSED_CANDIDATES:
      const candidates = action.payload
      const positions: { [id: number]: Position } = {}
      // TODO this pre-processing hopefully won't be needed in future code changes
      candidates.forEach((candidate: Candidate) =>
        candidate.candidacies.forEach((candidacy: Candidacy) => {
          if (!positions[candidacy.position.id]) {
            positions[candidacy.position.id] = candidacy.position
          }
        }),
      )
      return {
        ...state,
        candidates,
        positions: Object.values(positions),
      }
    case types.REQUEST_SEARCH_DATA:
      return {
        ...state,
        searchTerm: action.payload,
        searching: true,
      }
    case types.RECEIVE_SEARCH_DATA:
      return {
        ...state,
        searchResults: action.payload,
        searching: false,
      }

    case types.SET_SEARCH_INITIATED:
      return {
        ...state,
        searchInitiated: action.payload,
      }

    default:
      return state
  }
}
