import * as types from './constants'
import qs from 'qs'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { StoreState } from 'types'
import { AnalyticsContextType } from 'contexts/AnalyticsContext/AnalyticsContext'

export const searchInitiated = (payload: unknown) => ({
  type: types.SET_SEARCH_INITIATED,
  payload,
})

const requestSearchData = (payload: unknown) => ({
  type: types.REQUEST_SEARCH_DATA,
  payload,
})

const receiveSearchData = (payload: unknown) => ({
  type: types.RECEIVE_SEARCH_DATA,
  payload,
})

export default (
  searchTerm: string,
  analytics?: AnalyticsContextType,
): ThunkAction<void, StoreState, null, Action<string>> => async (
  dispatch,
  getState,
) => {
  const {
    appConfig: { defaultElectionId, positionIdWhitelist },
    searchResults,
  } = getState()
  if (searchResults.searchTerm === searchTerm) {
    return
  }

  const ballotApiUrl = process.env.REACT_APP_BALLOT_API_URL

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  dispatch(requestSearchData(searchTerm))

  const params = qs.stringify(
    {
      election_id: defaultElectionId,
      name: searchTerm,
      position_id: positionIdWhitelist?.join(),
    },
    { skipNulls: true },
  )

  const response = await fetch(`${ballotApiUrl}/search?${params}`, {
    method: 'GET',
    headers,
  })
  // track called here as opposed to in the component to ensure that
  // 'Searched' actions correspond with the actual API request that is made
  // as well as to ensure that we don't spam clickstream with duplicate and/or
  // unimportant actions taken in the search bar
  analytics?.track('Searched candidate or position', {
    search: searchTerm,
  })
  if (!response.ok) {
    return
  }

  const json = await response.json()
  return dispatch(receiveSearchData(json))
}
