import { Person } from '@ballotready/redux-api-client'

export default (person: Person): string =>
  [
    person.first_name,
    person.nickname ? `"${person.nickname}"` : null,
    person.middle_name,
    person.last_name,
    person.suffix,
  ]
    .filter(n => n)
    .join(' ')
