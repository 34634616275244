import { connect } from 'react-redux'
import { saveBallot } from '@ballotready/redux-api-client'
import { ThunkDispatch } from 'redux-thunk'
import { StoreState } from 'types'
import { Action } from 'redux'

const mapStateToProps = ({ appConfig: { supportedLocales } }: StoreState) => ({
  locales: supportedLocales,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, null, Action>,
) => ({
  onLocaleChange: (newLocale: string) =>
    dispatch(saveBallot({ locale: newLocale })),
})

export default connect(mapStateToProps, mapDispatchToProps)
